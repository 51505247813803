import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout/Layout';
import HomeCardsQL from '../components/HomeCards/HomeCardsQL';

// Goal: Render Contentful posts
// 1. Swap out the markdown query with the contentful query
// 2. Update the component to render the new data
// export default function Blog() {
//     const data = useStaticQuery(graphql`
//         query {
//             allContentfulBlogPost ( sort: { fields: publishDate, order: DESC }) {
//                 edges {
//                     node {
//                         title
//                         slug
//                         publishDate(formatString: "MMMM Do, YYYY")
//                     }
//                 }
//             }
//         }
//     `)

//     return (
//         <Layout>
//             <SEO title="Blog" />
//             <h1>Blog</h1>
//             <ol>
//                 {data.allContentfulBlogPost.edges.map((edge) => {
//                     return (
//                         <li>
//                             <Link to={`/blog/${edge.node.slug}`}>
//                                 <h2>{edge.node.title}</h2>
//                                 <p>{edge.node.publishDate}</p>
//                             </Link>
//                         </li>
//                     )
//                 })}
//             </ol>
//         </Layout>
//     )
// }


// Goal: Link to blog posts
// 1. Fetch the slug for posts
// 2. Use slug to generate a link to the post page
export default function Assignment() {
    // const data = useStaticQuery(graphql`
    //     query {
    //         allMarkdownRemark {
    //             edges {
    //                 node {
    //                     frontmatter {
    //                         weekId
    //                         title
    //                         publishDate
    //                     }
    //                     fields {
    //                         slug
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // `);

    return (
        <Layout>
            <HomeCardsQL />
            {/* <h1>Assignment</h1>
            <ol>
                {data.allMarkdownRemark.edges.map((edge) => {
                    return (
                        <li>
                            <Link to={`/assignment/${edge.node.fields.slug}`}>
                                <h2>{edge.node.frontmatter.title}</h2>
                                <p>{edge.node.frontmatter.publishDate}</p>
                            </Link>
                        </li>
                    )
                })}
            </ol> */}
        </Layout>
    )
}